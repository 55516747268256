import { Loader, Paper, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient, SCWithStateAssignements } from "../../../generated";
import { useFetchAllTenants } from "../../../hooks/useFetchAllTenants";
import StructuralComponentTree from "../../Views/StructuralComponentTree";

const UpdateStateAssignement = () => {
  const apiClient = useApiClient();

  const [selectedTenant, setSelectedTenant] = useState<string | null>("");
  const [structuralComponentSelection, setStructuralComponentSelection] =
    useState<string[]>([]);
  const [structuralData, setStructuralData] = useState<
    SCWithStateAssignements[]
  >([]);

  const ts_sc_cache_key = ["fetchStructuralComponents", selectedTenant];
  const {
    data: structuralComponentData,
    error: structuralError,
    isLoading: isLoadingStructuralComponents,
    isValidating: isFetchingStructuralComponents,
  } = useSWR(
    ts_sc_cache_key,
    () => fetchStructuralComponents(apiClient, selectedTenant),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );

  const handleTenantChange = (tenant: string | null) => {
    setSelectedTenant(tenant);
    setStructuralComponentSelection([]);
  };

  useEffect(() => {
    if (structuralComponentData) {
      setStructuralData(structuralComponentData);
    }
  }, [structuralComponentData]);

  // Get Tenant Labels
  const { tenantLabels, tenantError } = useFetchAllTenants(apiClient);
  if (tenantError) {
    return <p>{tenantError.message}</p>;
  } else if (!tenantLabels) {
    return <Loader mt="lg" />;
  }
  if (structuralError) return <p>Error: {structuralError.message}</p>;

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Select
          mb="md"
          label="Tenant"
          placeholder="Select tenant"
          data={tenantLabels}
          value={selectedTenant}
          onChange={handleTenantChange}
        />
        {isLoadingStructuralComponents && <Loader />}
        {structuralData && (
          <StructuralComponentTree
            tenantId={selectedTenant || ""}
            structuralData={structuralData}
            setStructuralData={setStructuralData}
            selectedIdentifiers={structuralComponentSelection}
            setSelectedIdentifiers={setStructuralComponentSelection}
            isFetching={isFetchingStructuralComponents}
          />
        )}
      </Paper>
    </>
  );
};

export default UpdateStateAssignement;

const fetchStructuralComponents = (
  apiClient: BackendClient,
  tenant: string | null,
) => {
  if (!tenant) return;
  return apiClient.structuralComponent.getWithStateAssignmentsTenantsTenantIdStructuralcomponentsGet(
    tenant,
  );
};
