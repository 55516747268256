/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FitParams } from "./FitParams";
import type { InitParams } from "./InitParams";
import type { MlProcessorType } from "./MlProcessorType";
import type { PredictParams } from "./PredictParams";

export type MlProcessor = {
  basetype?: MlProcessor.basetype;
  type?: MlProcessorType;
  training_start_date?: Array<string>;
  training_end_date?: Array<string>;
  last_training?: string;
  last_prediction?: string;
  last_prediction_value?: number;
  fit_params?: FitParams;
  init_params?: InitParams;
  predict_params?: PredictParams;
  filter_rule?: string;
  auto_training?: boolean;
  sensitivity?: number;
};

export namespace MlProcessor {
  export enum basetype {
    ML_PROC = "ml_proc",
  }
}
