/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TSNamingResponse } from "../models/TSNamingResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class TimeSeriesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get List
   * Get information about a tenant.
   * @param tenantId
   * @param xTenantId
   * @returns TSNamingResponse Successful Response
   * @throws ApiError
   */
  public getListTenantsTenantIdTimeseriesGet(
    tenantId: string,
    xTenantId?: string,
  ): CancelablePromise<Array<TSNamingResponse>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/timeseries",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
