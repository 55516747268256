import { useEffect, useState } from "react";

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

function sortData<T extends Record<StringKeys<T>, string>>(
  data: T[],
  payload: {
    sortBy: keyof T | null;
    reversed: boolean;
    search: string;
  },
  filterData: (data: T[], search: string) => T[],
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search,
  );
}

const useSearchAndSort = <T extends Record<StringKeys<T>, string>>(
  data: T[] | undefined,
  filterData: (data: T[], search: string) => T[],
) => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<keyof T | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [sortedData, setSortedData] = useState(data);

  const setSorting = (field: keyof T) => {
    if (!data) return;
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData<T>(data, { sortBy: field, reversed, search }, filterData),
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData<T>(
        data,
        {
          sortBy,
          reversed: reverseSortDirection,
          search: value,
        },
        filterData,
      ),
    );
  };

  useEffect(() => {
    if (data) {
      setSortedData(
        sortData<T>(
          data,
          {
            sortBy,
            reversed: reverseSortDirection,
            search,
          },
          filterData,
        ),
      );
    }
  }, [data, sortBy, reverseSortDirection, search, filterData]);

  return {
    search,
    sortBy,
    reverseSortDirection,
    sortedData,
    setSortedData,
    setSorting,
    handleSearchChange,
  };
};

export default useSearchAndSort;
