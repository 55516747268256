import {
  Box,
  Button,
  Checkbox,
  Flex,
  Loader,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Select,
  Table,
  TextInput,
  keys,
  rem,
} from "@mantine/core";
import "@mantine/dates/styles.css";
import { IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient, TSProcessorResponse } from "../../../generated";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";
import { useFetchAllTenants } from "../../../hooks/useFetchAllTenants";
import useSearchAndSort from "../../../hooks/useSearchAndSort";
import { EnumEnvironment } from "../../../types/environment.type";
import combine from "../../../utils/combine";
import MultiRangeDatePicker from "../../Controls/MultiDateRangePicker";
import Th from "../../Tables/Th.tsx";

dayjs.extend(customParseFormat);

const fetchLastTrainingTimes = (apiClient: BackendClient, tenant: string) => {
  if (!tenant) {
    return;
  }
  return apiClient.processor.getListTenantsTenantIdentifierTsprocessorsGet(
    tenant,
  );
};

interface DateRange {
  start: Date;
  end: Date;
}

function filterData(data: TSProcessorResponse[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) =>
      item[key].toString().toLowerCase().includes(query),
    ),
  );
}

const PredictTenant = () => {
  const apiClient = useApiClient();

  // Tenant Selection
  const [selectedTenant, setSelectedTenant] = useState<string | null>("");
  const { environment } = useEnvironmentStore();

  // Model Selection
  const [modelSelection, setModelSelection] = useState([] as string[]);
  const [isTrainingLoading, setIsTrainingLoading] = useState<boolean>(false);
  const cache_key = ["fetchLastTrainingTimes", selectedTenant];
  const { data: modelData, isLoading: modelDataIsLoading } = useSWR(
    cache_key,
    () => fetchLastTrainingTimes(apiClient, selectedTenant || ""),
  );

  // Date Range Selection
  const [ranges, setRanges] = useState<DateRange[]>([]);

  // Search and Sort
  const {
    search,
    sortBy,
    reverseSortDirection,
    sortedData,
    setSorting,
    handleSearchChange,
  } = useSearchAndSort<TSProcessorResponse>(modelData, filterData);

  // Get Tenant Labels
  const { tenantLabels, tenantError } = useFetchAllTenants(apiClient);
  if (tenantError) {
    return <p>{tenantError.message}</p>;
  } else if (!tenantLabels) {
    return <Loader mt="lg" />;
  }

  // Predict Models
  const handlePredictModels = async () => {
    if (!ranges[0]) {
      toast.warning("Please select at least one range");
      return;
    }
    if (modelSelection.length < 1) {
      toast.warning("Please select at least one model to predict");
      return;
    }
    if (
      environment !== EnumEnvironment.Production &&
      environment !== EnumEnvironment.Staging
    ) {
      toast.warning(`Predict API cannot be used in ${environment} environment`);
      return;
    }

    if (
      !window.confirm(
        `Are you sure you want to predict ${modelSelection.length} models for ${selectedTenant}?`,
      )
    )
      return;

    const baseUrl = process.env.REACT_APP_MODEL_API_URL;
    if (!baseUrl) {
      toast.error("Error: REACT_APP_MODEL_API_URL is not set");
      return;
    }
    const url = combine(baseUrl, "model/predict");
    const body = JSON.stringify({
      id_item: modelSelection,
      start_time: ranges.map((range) =>
        dayjs(range.start).format("YYYY-MM-DD HH:mm:ss"),
      ),
      end_time: ranges.map((range) =>
        dayjs(range.end).format("YYYY-MM-DD HH:mm:ss"),
      ),
    });

    try {
      setIsTrainingLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      if (response.ok) {
        toast.success(
          `Request successfully sent to predict ${modelSelection.length} models for ${selectedTenant}`,
        );
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error: ${error}`);
    }
    setIsTrainingLoading(false);
  };

  // Model Table
  const toggleRow = (id: string) =>
    setModelSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id],
    );
  const toggleAll = () => {
    if (!sortedData) return;
    setModelSelection((current) =>
      current.length === sortedData.length
        ? []
        : sortedData.map((model) => model.id),
    );
  };
  const trainingTable = sortedData?.map((model) => {
    return (
      <Table.Tr key={model.id}>
        <Table.Td>
          <Checkbox
            checked={modelSelection.includes(model.id)}
            onChange={() => toggleRow(model.id)}
          />
        </Table.Td>
        <Table.Td>{model.identifier}</Table.Td>
        <Table.Td>{model.last_training || "-"}</Table.Td>
        <Table.Td>{model.exclusion_start_dates || "-"}</Table.Td>
        <Table.Td>{model.exclusion_end_dates || "-"}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <ScrollArea>
          <Select
            label="Select Tenant"
            placeholder="Pick tenant"
            data={tenantLabels}
            value={selectedTenant}
            onChange={setSelectedTenant}
          />

          <MultiRangeDatePicker value={ranges} onChange={setRanges} />

          {selectedTenant && sortedData != null && (
            <Box pos="relative">
              <LoadingOverlay
                visible={modelDataIsLoading}
                zIndex={1000}
                overlayProps={{ radius: "sm", blur: 2 }}
              />
              <TextInput
                label="Search and select models"
                placeholder="Search by any field"
                mt="md"
                leftSection={
                  <IconSearch
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
                value={search}
                onChange={handleSearchChange}
              />
              <Table stickyHeader striped>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Th style={{ width: rem(50) }}>
                      <Checkbox
                        onChange={toggleAll}
                        checked={modelSelection.length === tenantLabels.length}
                        indeterminate={
                          modelSelection.length > 0 &&
                          modelSelection.length !== tenantLabels.length
                        }
                      />
                    </Table.Th>
                    <Th
                      sorted={sortBy === "identifier"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("identifier")}
                    >
                      Identifier
                    </Th>
                    <Th
                      sorted={sortBy === "last_training"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("last_training")}
                    >
                      Last Training
                    </Th>
                    <Th
                      sorted={sortBy === "exclusion_start_dates"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("exclusion_start_dates")}
                    >
                      Exclusion Start Dates
                    </Th>
                    <Th
                      sorted={sortBy === "exclusion_end_dates"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("exclusion_end_dates")}
                    >
                      Exclusion End Dates
                    </Th>
                  </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>{trainingTable}</Table.Tbody>
              </Table>
            </Box>
          )}
        </ScrollArea>

        <Flex mt="xl" gap="md" align="flex-start" direction="column">
          {modelDataIsLoading && sortedData == null && <Loader />}
          <Button
            color="green"
            variant="light"
            disabled={!(selectedTenant && modelSelection.length)}
            onClick={handlePredictModels}
            loading={isTrainingLoading}
          >
            {selectedTenant && modelSelection.length
              ? `Predict ${modelSelection.length} Model${
                  modelSelection.length > 1 ? "s" : ""
                }`
              : "Select Tenant and Models"}
          </Button>
        </Flex>
      </Paper>
    </>
  );
};

export default PredictTenant;
