import useSWR from "swr";
import { BackendClient } from "../generated";

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

export const useFetchAllTenants = (
  apiClient: BackendClient,
  justLabels: boolean = true,
) => {
  const {
    data: tenantData,
    error: tenantError,
    isLoading: isLoadingTenants,
  } = useSWR(["fetchAlltenants"], () => fetchAlltenants(apiClient));
  if (tenantError) return { tenantLabels: undefined, tenantError: tenantError };

  if (isLoadingTenants || !tenantData)
    return { tenantLabels: undefined, tenantError: undefined };
  else {
    if (!justLabels)
      return {
        tenantLabels: tenantData.map((tenant) => tenant.identifier || ""),
        tenantData,
        tenantError: undefined,
      };
    else {
      return {
        tenantLabels: tenantData.map((tenant) => tenant.identifier || ""),
        tenantError: undefined,
      };
    }
  }
};
