import { ComparisonOperator, LogicalOperator } from "../generated";

export interface RuleLogicType {
  timeSeries: string;
  comparisonOperator: ComparisonOperator;
  threshold: number;
  operator?: LogicalOperator;
}

export interface AssignmentType {
  stateIdentifier: string;
  timeSeriesIdentifier: string;
  operator: ComparisonOperator;
  value: number;
  signature?: string;
}

export const operatorData = [
  { value: ComparisonOperator.GT, label: ">" },
  { value: ComparisonOperator.LT, label: "<" },
  { value: ComparisonOperator.EQ, label: "=" },
  { value: ComparisonOperator.GE, label: "≥" },
  { value: ComparisonOperator.LE, label: "≤" },
];
