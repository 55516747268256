import { Paper, Text, Title } from "@mantine/core";
import DownloadSection from "../../components/Data/Download";

const DataDownloadPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Download
      </Title>
      <Text mb="lg">
        Download data in the given timeframe from the selected data source
      </Text>
      <DownloadSection />
    </Paper>
  );
};

export default DataDownloadPage;
