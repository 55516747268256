/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible data types for a timeseries
 */
export enum TsDataType {
  FLOAT = "float",
  INT = "int",
  CATEGORICAL = "categorical",
}
