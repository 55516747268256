/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class DataService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Data
   * Download timeseries data for given tenant as csv.
   * @param tenantId
   * @param start
   * @param end
   * @param xTenantId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDataDataTenantIdPost(
    tenantId: string,
    start?: string,
    end?: string,
    xTenantId?: string,
    requestBody?: Array<string>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/data/{tenant_id}",
      path: {
        tenant_id: tenantId,
      },
      query: {
        start: start,
        end: end,
        X_tenant_id: xTenantId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
