import {
  Button,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ComparisonOperator } from "../../../generated";
import { AssignmentType, operatorData } from "../../../types/state.type";

interface CreateUpdateAssignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveSimilar: (assignment: AssignmentType) => void;
  allTimeSeriesIds: string[];
  allStateIds: string[];
  existingAssignment?: AssignmentType | null;
}

const CreateUpdateAssignmentModal: React.FC<
  CreateUpdateAssignmentModalProps
> = ({
  isOpen,
  onClose,
  onSaveSimilar,
  allTimeSeriesIds,
  allStateIds,
  existingAssignment,
}) => {
  const isEditMode = !!existingAssignment;

  const [stateIdentifier, setStateIdentifier] = useState<string | null>();
  const [timeseriesIdentifier, setTimeseriesIdentifier] = useState<
    string | null
  >();
  const [operator, setOperator] = useState<ComparisonOperator | null>(null);
  const [value, setValue] = useState<string | number>("");
  const [signature, setSignature] = useState<string | undefined>();

  const resetState = () => {
    setStateIdentifier("");
    setTimeseriesIdentifier("");
    setOperator(null);
    setValue("");
    setSignature(undefined);
  };

  useEffect(() => {
    if (existingAssignment) {
      setStateIdentifier(existingAssignment.stateIdentifier);
      setTimeseriesIdentifier(existingAssignment.timeSeriesIdentifier);
      setOperator(existingAssignment.operator);
      setValue(existingAssignment.value);
      setSignature(existingAssignment.signature);
    } else {
      resetState();
    }
  }, [existingAssignment]);

  const handleSave = () => {
    if (
      !stateIdentifier ||
      !timeseriesIdentifier ||
      !operator ||
      typeof value !== "number"
    ) {
      toast.error("Please fill all required fields");
      return;
    }
    onSaveSimilar({
      stateIdentifier: stateIdentifier,
      timeSeriesIdentifier: timeseriesIdentifier,
      operator: operator,
      value: value,
      signature: signature,
    });
    resetState();
    onClose();
  };
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={isEditMode ? "Update Assignment" : "Create Assignment"}
    >
      <Stack>
        <Select
          label="State Identifier"
          data={allStateIds}
          value={stateIdentifier}
          onChange={setStateIdentifier}
          searchable
          required
        />
        <Select
          label="Timeseries Identifier"
          data={allTimeSeriesIds}
          value={timeseriesIdentifier}
          onChange={setTimeseriesIdentifier}
          searchable
          required
        />
        <Select
          label="Operator"
          data={operatorData}
          value={operator}
          onChange={(value) => {
            if (
              !Object.values(ComparisonOperator).includes(
                value as ComparisonOperator,
              )
            ) {
              setOperator(null);
              return;
            }
            setOperator(value as ComparisonOperator);
          }}
          required
        />
        <NumberInput label="Value" value={value} onChange={setValue} required />
        <Group mt="md">
          <Button onClick={handleSave}>
            {isEditMode ? "Update" : "Create"}
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CreateUpdateAssignmentModal;
