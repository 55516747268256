import { Paper, Text, Title } from "@mantine/core";
// import UploadSection from "../../components/Data/Upload";

const DataUploadPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Upload
      </Title>
      <Text mb="lg">This feature will come in a future release</Text>
      {/* <UploadSection /> */}
    </Paper>
  );
};

export default DataUploadPage;
