import {
  Button,
  Loader,
  Paper,
  ScrollArea,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { useSWRConfig } from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient, TSProcessorResponse } from "../../../generated";
import useExcelStore from "../../../hooks/useExcelStore";
import Th from "../Th.tsx";

const fetchModels = (apiClient: BackendClient, tenant: string) => {
  if (!tenant) {
    return;
  }
  return apiClient.processor.getListTenantsTenantIdentifierTsprocessorsGet(
    tenant,
  );
};

function sortData(
  data: TSProcessorResponse[],
  payload: { sortBy: keyof TSProcessorResponse | null; reversed: boolean },
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return data;
  }

  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[sortBy].toString().localeCompare(a[sortBy].toString());
    }

    return a[sortBy].toString().localeCompare(b[sortBy].toString());
  });
}

const ExclusionTable = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const tenant_identifier = sheetsData.Description?.tenant_identifier;
  const [sortBy, setSortBy] = useState<keyof TSProcessorResponse | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { mutate } = useSWRConfig();
  const cache_key = ["fetchExclusionTable", tenant_identifier];
  const {
    data: modelData,
    isLoading: modelDataIsLoading,
    error: modelError,
  } = useSWR(cache_key, () => fetchModels(apiClient, tenant_identifier || ""), {
    shouldRetryOnError: false,
  });
  const [sortedData, setSortedData] = useState(modelData);

  const handleUpdateButton = async () => {
    try {
      if (!tenant_identifier) throw new Error("Tenant is required");
      if (!sheetsData.Hierarchy) throw new Error("Hierarchy is required");
      if (!sheetsData.Exclusion) throw new Error("Exclusions is required");
      setIsUpdating(true);
      await apiClient.processor.updateExclusionsTenantsTenantIdentifierExclusionsPut(
        tenant_identifier,
        {
          hierarchy: sheetsData.Hierarchy,
          exclusions: sheetsData.Exclusion,
        },
      );
      toast.success("Exclusion periods updated successfully!");
      mutate(cache_key);
    } catch (error) {
      toast.error(`Failed to exclusion periods: ${error}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const setSorting = (field: keyof TSProcessorResponse) => {
    if (!modelData) return;
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(modelData, { sortBy: field, reversed }));
  };

  useEffect(() => {
    if (modelData) {
      setSortedData(
        sortData(modelData, { sortBy, reversed: reverseSortDirection }),
      );
    }
  }, [modelData, sortBy, reverseSortDirection]);

  const trainingTable = sortedData?.map((model) => {
    const renderStackedDates = (date: string) => {
      const dates = date ? date.split(", ") : ["N/A"];
      return (
        <div>
          {dates.map((date, index) => (
            <div key={index}>{date}</div>
          ))}
        </div>
      );
    };
    return (
      <Table.Tr key={model.id}>
        <Table.Td>{model.identifier}</Table.Td>
        <Table.Td>{renderStackedDates(model.exclusion_start_dates)}</Table.Td>
        <Table.Td>{renderStackedDates(model.exclusion_end_dates)}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Paper withBorder p="md" radius="md" mt="sm">
        <Title mb="md" order={3}>
          Exclusion Times in the Database (UTC)
        </Title>
        <ScrollArea>
          {!modelError && tenant_identifier && sortedData != null && (
            <>
              <Table stickyHeader striped>
                <Table.Tbody>
                  <Table.Tr>
                    <Th
                      sorted={sortBy === "identifier"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("identifier")}
                    >
                      Model Identifier
                    </Th>
                    <Th
                      sorted={sortBy === "exclusion_start_dates"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("exclusion_start_dates")}
                    >
                      Start Dates
                    </Th>
                    <Th
                      sorted={sortBy === "exclusion_end_dates"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("exclusion_end_dates")}
                    >
                      End Dates
                    </Th>
                  </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>{trainingTable}</Table.Tbody>
              </Table>
            </>
          )}
          {modelError && (
            <Text>
              The tenant "{tenant_identifier}" was not found in the database.
            </Text>
          )}
          {modelDataIsLoading ? (
            <Loader mt="lg" />
          ) : (
            <Button
              mt="md"
              onClick={handleUpdateButton}
              loading={isUpdating}
              disabled={
                modelError ||
                !tenant_identifier ||
                !sheetsData.Hierarchy ||
                !sheetsData.Exclusion
              }
            >
              Update Exclusion Table From Excel
            </Button>
          )}
        </ScrollArea>
      </Paper>
    </>
  );
};

export default ExclusionTable;
