import {
  Button,
  Checkbox,
  Flex,
  Loader,
  Paper,
  ScrollArea,
  Select,
  Table,
  TextInput,
  keys,
  rem,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient, TSNamingResponse } from "../../../generated";
import { useFetchAllTenants } from "../../../hooks/useFetchAllTenants";
import useSearchAndSort from "../../../hooks/useSearchAndSort";
import Th from "../../Tables/Th.tsx";

const tsNamings = (apiClient: BackendClient, tenant: string) => {
  if (!tenant) {
    return;
  }
  return apiClient.timeSeries.getListTenantsTenantIdTimeseriesGet(tenant);
};

function filterData(data: TSNamingResponse[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => {
      const value = item[key];
      return typeof value === "string" && value.toLowerCase().includes(query);
    }),
  );
}

const DownloadSelector = () => {
  const apiClient = useApiClient();

  // Tenant Selection
  const [selectedTenant, setSelectedTenant] = useState<string | null>("");

  // Timeseries Selection
  const [tsSelection, setTsSelection] = useState<string[]>([]);
  const [minMaxDate, setMinMaxDate] = useState<Date[] | null>(null);
  const [isTsDataLoading, setIsTsDataLoading] = useState<boolean>(false);
  const cache_key = ["tsNamings", selectedTenant];
  const { data: tsNamingsData, isLoading: isLoadingTsNamings } = useSWR(
    cache_key,
    () => tsNamings(apiClient, selectedTenant || ""),
  );

  // Search and Sort
  const {
    search,
    sortBy,
    reverseSortDirection,
    sortedData,
    setSorting,
    handleSearchChange,
  } = useSearchAndSort<TSNamingResponse>(tsNamingsData, filterData);

  // Download
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const defaultFilename = "export";
  const [filename, setFilename] = useState<string>(defaultFilename);

  // Get Tenant Labels
  const { tenantLabels, tenantError } = useFetchAllTenants(apiClient);
  if (tenantError) {
    return <p>{tenantError.message}</p>;
  } else if (!tenantLabels) {
    return <Loader mt="lg" />;
  }

  // Handle Download
  const handleDownloadData = async () => {
    if (selectedTenant === null) {
      toast.error("Error: Tenant is not selected");
      return;
    }

    if (sortedData === undefined || sortedData.length === 0) {
      toast.warning("There is no data to download");
      return;
    }

    if (tsSelection.length < 1) {
      toast.warning("Please select at least one timeseries to download");
      return;
    }

    const selection =
      tsSelection.length === sortedData.length ? undefined : tsSelection;

    try {
      setIsTsDataLoading(true);

      const response = await apiClient.data.getDataDataTenantIdPost(
        selectedTenant,
        start ? dayjs(start).format("YYYY-MM-DD HH:mm:ss") : undefined,
        end ? dayjs(end).format("YYYY-MM-DD HH:mm:ss") : undefined,
        undefined,
        selection,
      );

      saveAs(
        new Blob([response], { type: "text/csv" }),
        filename.endsWith(".csv") ? filename : `${filename}.csv`,
      );
    } catch (err) {
      toast.error(`Error: ${err}`);
    } finally {
      setIsTsDataLoading(false);
    }
  };

  // Timeseries Table
  const handleToggle = (updatedSelection: string[]) => {
    setMinMaxDate(() => {
      if (tsNamingsData === undefined || updatedSelection.length === 0)
        return null;

      const firstTS: number[] = [];
      const lastTS: number[] = [];

      const selectedData = tsNamingsData.filter((data) =>
        updatedSelection.includes(data.id),
      );

      selectedData.forEach((data) => {
        firstTS.push(dayjs(data.first_timestamp).valueOf());
        lastTS.push(dayjs(data.last_timestamp).valueOf());
      });

      const minTimestamp = Math.min(...firstTS);
      const maxTimestamp = Math.max(...lastTS);

      return [new Date(minTimestamp), new Date(maxTimestamp)];
    });
  };
  const toggleRow = (id: string) => {
    setTsSelection((current) => {
      const newSelection = current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id];
      handleToggle(newSelection);
      return newSelection;
    });
  };
  const toggleAll = () => {
    if (!sortedData) return;
    setTsSelection((current) => {
      const newSelection =
        current.length === sortedData.length
          ? []
          : sortedData.map((ts) => ts.id);
      handleToggle(newSelection);
      return newSelection;
    });
  };
  const TsDataTable = sortedData?.map((timeseries) => {
    return (
      <Table.Tr key={timeseries.id}>
        <Table.Td>
          <Checkbox
            checked={tsSelection.includes(timeseries.id)}
            onChange={() => toggleRow(timeseries.id)}
          />
        </Table.Td>
        <Table.Td>{timeseries.identifier}</Table.Td>
        <Table.Td>{timeseries.ts_origin_identifier}</Table.Td>
        <Table.Td>{timeseries.ts_origin_type || "-"}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <ScrollArea>
          <Select
            label="Select Tenant"
            placeholder="Pick Tenant"
            data={tenantLabels}
            value={selectedTenant}
            onChange={setSelectedTenant}
          />
          {selectedTenant && sortedData != null && (
            <>
              <TextInput
                label="Search and select Timeseries"
                placeholder="Search by any field"
                mt="lg"
                leftSection={
                  <IconSearch
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
                value={search}
                onChange={handleSearchChange}
              />
              <div
                style={{
                  maxHeight: 600,
                  overflowY: "auto",
                }}
              >
                <Table stickyHeader striped>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th style={{ width: rem(50) }}>
                        <Checkbox
                          onChange={toggleAll}
                          checked={tsSelection.length === tenantLabels.length}
                          indeterminate={
                            tsSelection.length > 0 &&
                            tsSelection.length !== tenantLabels.length
                          }
                        />
                      </Table.Th>
                      <Th
                        sorted={sortBy === "identifier"}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting("identifier")}
                      >
                        Timeseries Identifier
                      </Th>
                      <Th
                        sorted={sortBy === "ts_origin_identifier"}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting("ts_origin_identifier")}
                      >
                        TS Origin Label
                      </Th>
                      <Th
                        sorted={sortBy === "ts_origin_type"}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting("ts_origin_type")}
                      >
                        TS Origin Label
                      </Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{TsDataTable}</Table.Tbody>
                </Table>
              </div>
              <Flex
                mt="xl"
                gap="md"
                align="flex-end"
                direction="row"
                style={{ width: "100%" }}
                display={
                  selectedTenant && !isLoadingTsNamings ? "flex" : "none"
                }
              >
                <DateTimePicker
                  label="Select Start"
                  highlightToday
                  withSeconds
                  valueFormat="DD MMM YYYY HH:mm:ss"
                  placeholder="from the first value"
                  clearable
                  style={{ flexBasis: 225 }}
                  onChange={setStart}
                  minDate={minMaxDate ? minMaxDate[0] : undefined}
                  maxDate={end || (minMaxDate ? minMaxDate[1] : undefined)}
                  timeInputProps={{
                    maxTime: end ? end.toTimeString() : undefined,
                  }}
                />
                <DateTimePicker
                  label="Select End"
                  highlightToday
                  withSeconds
                  valueFormat="DD MMM YYYY HH:mm:ss"
                  placeholder="until the latest value"
                  clearable
                  style={{ flexBasis: 225 }}
                  onChange={setEnd}
                  minDate={start || (minMaxDate ? minMaxDate[0] : undefined)}
                  maxDate={minMaxDate ? minMaxDate[1] : undefined}
                  timeInputProps={{
                    minTime: start ? start.toTimeString() : undefined,
                  }}
                />
                <TextInput
                  label="Filename"
                  placeholder={defaultFilename + ".csv"}
                  onChange={(event) => setFilename(event.currentTarget.value)}
                  style={{ flexBasis: 150 }}
                />
                <Button
                  color="green"
                  variant="light"
                  disabled={isTsDataLoading || tsSelection.length < 1}
                  onClick={handleDownloadData}
                  style={{ flexBasis: 300 }}
                >
                  {tsSelection.length
                    ? `Download Data from ${tsNamingsData && tsSelection.length === tsNamingsData.length ? "every" : tsSelection.length} Timeseries`
                    : `Select at least one Timeseries`}
                </Button>
                {isTsDataLoading && <Loader />}
              </Flex>
            </>
          )}
          <Flex mt="xl">{isLoadingTsNamings && <Loader />}</Flex>
        </ScrollArea>
      </Paper>
    </>
  );
};

export default DownloadSelector;
